<template>
  <div class="register">
    <main :class="{ component3: component === 'Register3' }">
      <router-link to="/">
        <img class="logo__large" src="@/assets/icons/logo_white.svg" alt="teamee Logo" title="teamee - Home" />
      </router-link>

      <component :is="component" />
    </main>
    <AppFooterRegistration />
  </div>
</template>

<script>
import AppFooterRegistration from "@/components/AppFooterRegistration.vue";
import Register1 from "@/components/Register-1.vue";
import Register2 from "@/components/Register-2.vue";
import Register3 from "@/components/Register-3.vue";
import Register4 from "@/components/Register-4.vue";

export default {
  name: "Register",
  components: {
    AppFooterRegistration,
    Register1,
    Register2,
    Register3,
    Register4,
  },
  computed: {
    component() {
      return this.$store.getters.REGISTERCOMPONENT;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

main {
  height: calc(100vh - 83px);
  display: flex;
  justify-content: center;
  .logo__large {
    position: fixed;
    top: 40px;
    background-color: $navy-blue;
    left: 48px;
    z-index: 600;
    @media screen and (max-width: 960px) {
      top: 20px;
      width: 100%;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}
.component3 {
  height: auto;
}
</style>

<template>
  <div class="content">
    <h2>Fast geschafft!</h2>
    <p>Um deinen Account zu aktivieren, klicke auf den Link in der E-Mail, die wir dir geschickt haben.</p>
    <div>
      <p>Keine E-Mail erhalten?</p>
      <button class="button__cta" @click="sendEmail" :disabled="isLoading">
        <span class="loader" v-if="isLoading"></span>
        <span class="txt" v-else>E-Mail erneut senden</span>
      </button>
    </div>
    <p class="resend-email-msg" v-if="resendEmailMsg">{{ resendEmailMsg }}</p>
    <router-link to="/" class="back-to-login h6">Zurück zum Login</router-link>
  </div>
</template>

<script>
export default {
  name: "Register-4",
  data() {
    return {
      isLoading: false,
      resendEmailMsg: null,
    };
  },
  methods: {
    // Resend Email with Activation Link
    sendEmail() {
      this.isLoading = true;
      this.$store
        .dispatch("resendEmail")
        .then((response) => {
          this.isLoading = false;
          console.log(response.data.msg);
          this.resendEmailMsg = response.data.msg;
        })
        .catch((error) => {
          // Account already verified
          this.isLoading = false;
          this.resendEmailMsg = error.response.data.message;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.content {
  max-width: 648px;
  align-self: center;
  @media screen and (max-width: 960px) {
    width: 100%;
    padding: 24px;
    align-self: flex-end;
    margin-bottom: 80px;
  }
  h2 {
    @media screen and (max-width: 960px) {
      font-size: 28px;
      line-height: 40px;
      margin-bottom: 12px;
    }
  }
  & > p {
    margin-bottom: 40px;
    @media screen and (max-width: 960px) {
      margin-bottom: 80px;
    }
  }
  div {
    display: flex;
    align-items: center;
    @media screen and (max-width: 960px) {
      flex-direction: column;
      align-items: flex-start;
    }
    p {
      margin-right: 24px;
      @media screen and (max-width: 960px) {
        margin-bottom: 16px;
      }
    }
    button {
      width: 201px;
      @media screen and (max-width: 960px) {
        width: 100%;
      }
    }
  }
  .resend-email-msg {
    margin-top: 16px;
  }
  .back-to-login {
    margin-top: 48px;
    text-decoration: underline;
  }
}
</style>

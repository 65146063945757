<template>
  <div class="content">
    <div class="top">
      <div class="content-wrapper">
        <h2>Wähle deine Interessen ({{ gameCount }}/3)</h2>
        <p>(Diese kannst du im Anschluss jeder Zeit wieder ändern)</p>
        <div class="wrapper__btn-skip">
          <button class="button__transparent btn-skip" @click="skipGamesAndRegister" :disabled="isLoading">
            <span class="loader" v-if="isLoading"></span>
            <span class="txt" v-else>Diesen Schritt überspringen</span>
          </button>
        </div>
      </div>
    </div>

    <div class="bottom">
      <div class="select-game-wrapper">
        <div
          v-for="(item, index) in games"
          :key="index"
          class="card-game-selection"
          @click="toggleGame(item)"
          :class="{ activeGame: registrationDataGames.some((data) => data.gameName === item.name) }"
        >
          <img :src="item.background_image" alt="Hero Bild" />
          <h4>{{ item.name }}</h4>
        </div>

        <div class="navigation">
          <button class="button__transparent" @click="showComponent('Register2')">Zurück</button>
          <button class="button__transparent" @click="completeRegistration" :disabled="isLoading">
            <span class="loader" v-if="isLoading"></span>
            <span class="txt" v-else>Weiter</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Register-3",
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    showComponent(name) {
      this.$store.commit("SET_REGISTER_COMPONENT", name);
    },
    toggleGame(item) {
      const gameID = item.id;
      const gameName = item.name;
      const gameImage = item.background_image;
      const gamePlatforms = item.platforms;
      const params = { gameID, gameName, gameImage, gamePlatforms };

      // check if not more than 3 games have been added
      // check if game has already been added
      if (this.gameCount < 3 && !this.registrationDataGames.some((data) => data.gameID === item.id)) {
        // store game in VUEX
        this.$store.commit("ADD_REGISTRATION_DATA_GAMES", params);
      } else if (this.registrationDataGames.some((data) => data.gameID === item.id)) {
        // remove game from VUEX if GameID in Vuex Array matches clicked GameID
        this.$store.commit("REMOVE_REGISTRATION_DATA_GAMES", params);
      }
    },
    completeRegistration() {
      const payload = this.$store.getters.REGISTRATION_DATA;
      this.isLoading = true;

      this.$store
        .dispatch("completeRegistration", payload)
        .then(() => {
          this.isLoading = false;
          this.$store.commit("SET_REGISTER_COMPONENT", "Register4");
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    skipGamesAndRegister() {
      this.$store.state.registrationData[1] = [];
      this.completeRegistration();
    },
  },
  computed: {
    games() {
      return this.$store.state.popularGames;
    },
    registrationDataGames() {
      return this.$store.getters.REGISTRATION_DATA[1];
    },
    gameCount() {
      return this.$store.getters.REGISTRATION_DATA[1].length;
    },
  },
  mounted() {
    this.$store.dispatch("getPopularGames");
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.content {
  .top {
    z-index: 500;
    width: fit-content;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: $navy-blue;

    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 960px) {
      padding: 24px;
      top: -20px;
      width: 100%;
    }
    h2 {
      margin-top: 120px;
      text-align: center;
      line-height: 1.5;
      @media screen and (max-width: 960px) {
        font-size: 28px;
        line-height: 40px;
        margin-bottom: 12px;
      }
    }
    p {
      text-align: center;
      margin-bottom: 32px;
      @media screen and (max-width: 960px) {
        margin-bottom: 18px;
        line-height: 24px;
      }
    }
    .wrapper__btn-skip {
      width: 640px;
      margin: 0 auto;
      display: flex;
      justify-content: flex-end;
      @media screen and (max-width: 960px) {
        width: 100%;
        max-width: 440px;
      }
      .btn-skip {
        margin-bottom: 32px;
        width: 257px;
        @media screen and (max-width: 960px) {
          width: 100%;
          margin-bottom: 12px;
          button {
            width: 100%;
          }
        }
      }
    }
  }
  .bottom {
    // margin-top: 412px;
    margin-top: 364px;
    @media screen and (max-width: 960px) {
      margin-top: 388px;
      width: 100%;
      padding: 0 24px;
    }
    .select-game-wrapper {
      margin-top: 0px;
      margin-bottom: 80px;
      width: 640px;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 960px) {
        width: 100%;
      }

      .card-game-selection {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 120px;
        border-radius: 5px;
        margin-bottom: 24px;
        cursor: pointer;
        &:hover {
          border: 2px solid $dark-orange; // active card
        }
        img {
          border-radius: 5px;
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &:after {
          border-radius: 5px;
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.7);
        }
        h4 {
          max-width: 95%;
          z-index: 100;
          line-height: 1;
          background-color: transparent;
          user-select: none;
          @media screen and (max-width: 960px) {
            font-size: 24px;
          }
        }
      }
    }
    .navigation {
      width: 640px;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 960px) {
        width: 100%;
      }
    }
  }
  .activeGame {
    border: 2px solid $dark-orange;
  }
  .button__transparent {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>

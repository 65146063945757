<template>
  <div class="content">
    <h2>Wähle einen Avatar</h2>

    <!-- Avatar Selection -->
    <div class="avatar-selection">
      <img class="arrow__large" src="@/assets/icons/Arrow_Left.svg" alt="Prev" @click="previousSlide()" />
      <div class="avatar-selector__registration">
        <div class="icon-container">
          <transition-group name="slide">
            <div class="slide" :class="slideMove" v-for="(slide, index) in avatars" :key="slide.id" v-show="avatarID == index">
              <!-- had index as key before -->
              <img :src="slide.img" alt="Avatar" />
            </div>
          </transition-group>
        </div>
      </div>
      <img class="arrow__large" src="@/assets/icons/Arrow_Right.svg" alt="Next" @click="nextSlide()" />
    </div>

    <div class="navigation">
      <button class="button__transparent" @click="showComponent('Register3')">Weiter</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Register-2",
  data() {
    return {
      avatars: [
        {
          id: "1",
          img: require("@/assets/icons/avatar_batman.svg"),
        },
        {
          id: "2",
          img: require("@/assets/icons/avatar_heisenberg.svg"),
        },
        {
          id: "3",
          img: require("@/assets/icons/avatar_santa.svg"),
        },
        {
          id: "4",
          img: require("@/assets/icons/avatar_wrestler.svg"),
        },
        {
          id: "5",
          img: require("@/assets/icons/avatar_sloth.svg"),
        },
        {
          id: "6",
          img: require("@/assets/icons/avatar_hipster.svg"),
        },
        {
          id: "7",
          img: require("@/assets/icons/avatar_jason.svg"),
        },
        {
          id: "8",
          img: require("@/assets/icons/avatar_zorro.svg"),
        },
        {
          id: "9",
          img: require("@/assets/icons/avatar_pirate.svg"),
        },
      ],
      slideMove: "forward",
    };
  },
  methods: {
    showComponent(name) {
      this.$store.commit("SET_REGISTER_COMPONENT", name);
    },
    activeClass(index) {
      if (this.avatarID == index) {
        return true;
      } else {
        return false;
      }
    },
    nextSlide() {
      this.slideMove = "forward";
      if (this.avatarID + 1 == this.slideLength) {
        this.avatarID = 0;
      } else {
        this.avatarID++;
      }
    },
    previousSlide() {
      this.slideMove = "backward";
      if (this.avatarID == 0) {
        this.avatarID = this.slideLength - 1;
      } else {
        this.avatarID--;
      }
    },
  },
  computed: {
    slideLength() {
      return this.avatars.length;
    },
    avatarID: {
      // get current Avatar ID
      get: function() {
        return this.$store.getters.REGISTRATION_DATA[0].avatarID;
      },
      // set current Avatar ID
      set: function(avatarID) {
        const params = { index: 0, avatarID: avatarID };
        this.$store.commit("SET_REGISTRATION_DATA", params);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
  align-self: center;
  margin-top: auto;
  margin-bottom: 25vh;
  @media screen and (max-width: 960px) {
    align-self: flex-end;
    width: 100%;
    max-width: 440px;
    padding: 24px;
    margin: 0;
  }
  h2 {
    text-align: center;
    @media screen and (max-width: 960px) {
      font-size: 32px;
      line-height: 40px;
    }
  }
  .avatar-selection {
    margin: 80px 0 140px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 500px;
    @media screen and (max-width: 960px) {
      width: 100%;
    }
    .avatar-selector__registration {
      width: 140px;
      height: 140px;
      border-radius: 30px;
      background: $gradient;
      display: flex;
      justify-content: center;
      align-items: center;
      .icon-container {
        overflow: hidden;
        width: 134px;
        height: 134px;
        border-radius: 30px;
        padding: 8px;
        background: $navy-blue;
      }
    }
  }
  .navigation {
    width: 400px;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 960px) {
      width: 100%;
    }
    .button__transparent {
      width: 100%;
    }
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: 0.4s ease-in-out;
}
.slide-enter.forward {
  transform: translateX(150px);
  opacity: 0;
}
.slide-leave-to.forward {
  transform: translateX(-150px);
  opacity: 0;
}
.slide-enter.backward {
  transform: translateX(-150px);
  opacity: 0;
}
.slide-leave-to.backward {
  transform: translateX(150px);
  opacity: 0;
}
</style>

<template>
  <form>
    <div class="input__text__label">
      <input
        class="input__text floating-input"
        :class="{ input__text__danger: $v.username.$error || errors.username }"
        type="text"
        title="Username"
        placeholder=" "
        v-model.trim="$v.username.$model"
      />
      <label>Username</label>
      <!-- Error Message -->
      <div v-if="$v.username.$error || errors.username" class="error">
        <li v-if="errors.username" class="error-msg">{{ errors.username[0] }}</li>
        <li v-if="!$v.username.checkUsernamePattern" class="error-msg">Es sind nur Buchstaben und Zahlen zwischen 4 und 12 Zeichen erlaubt</li>
        <li v-if="!$v.username.minLength" class="error-msg">Dein Username sollte mindestens {{ $v.username.$params.minLength.min }} Zeichen enthalten.</li>
        <li v-if="!$v.username.maxLength" class="error-msg">Dein Username darf nicht mehr als {{ $v.username.$params.maxLength.max }} Zeichen enthalten.</li>
      </div>
    </div>

    <div class="input__text__label">
      <input
        class="input__text floating-input"
        :class="{ input__text__danger: $v.email.$error || errors.email }"
        type="mail"
        title="E-Mail"
        placeholder=" "
        v-model.trim="$v.email.$model"
      />
      <label>E-Mail</label>
      <!-- Error Message -->
      <div v-if="$v.email.$error || errors.email" class="error">
        <li v-if="errors.email" class="error-msg">{{ errors.email[0] }}</li>
        <li v-if="!$v.email.required" class="error-msg">Wir brauchen eine E-Mail Adresse von dir</li>
        <li v-if="!$v.email.email" class="error-msg">Bitte gib eine gültige E-Mail Adresse an</li>
      </div>
    </div>

    <div class="input__text__label">
      <input
        class="input__text floating-input"
        :class="{ input__text__danger: $v.password.$error || errors.password }"
        type="password"
        title="Passwort"
        placeholder=" "
        v-model.trim="$v.password.$model"
      />
      <label>Passwort</label>
      <!-- Error Message -->
      <div v-if="$v.password.$error || errors.password" class="error">
        <li v-if="errors.password" class="error-msg">{{ errors.password[0] }}</li>
        <li v-if="!$v.password.checkPasswordPattern" class="error-msg">Dein Passwort sollte aus mindestend 6 Buchstaben, Zahlen und Sonderzeichen bestehen</li>
      </div>
    </div>

    <div class="input__text__label">
      <input
        class="input__text floating-input"
        :class="{ input__text__danger: $v.password_confirmation.$error || errors.password_confirmation }"
        type="password"
        title="Passwort wiederholen"
        placeholder=" "
        v-model.trim="$v.password_confirmation.$model"
      />
      <label>Passwort wiederholen</label>
      <!-- Error Message -->
      <div v-if="$v.password_confirmation.$error || errors.password_confirmation" class="error">
        <li v-if="errors.password_confirmation" class="error-msg">{{ errors.password_confirmation[0] }}</li>
        <li v-if="!$v.password_confirmation.required" class="error-msg">Bitte bestätige dein Passwort</li>
        <li v-if="!$v.password_confirmation.sameAsPassword" class="error-msg">Deine Passwortangaben müssen übereinstimmen</li>
      </div>
    </div>

    <div class="navigation">
      <router-link to="/"><button class="button__transparent">Zurück</button></router-link>
      <button class="button__transparent btn-next" @click.prevent="register" :disabled="isLoading">
        <span class="loader" v-if="isLoading"></span>
        <span class="txt" v-else>Weiter</span>
      </button>
    </div>
  </form>
</template>

<script>
import { required, minLength, maxLength, email, sameAs } from "vuelidate/lib/validators";

export default {
  name: "Register-1",
  data() {
    return {
      username: "",
      email: "",
      password: "",
      password_confirmation: "",
      isLoading: false,
      errors: [],
      clearErrors: false,
    };
  },
  validations: {
    username: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(12),
      checkUsernamePattern(username) {
        return /^[a-zA-Z0-9]+$/.test(username); // check for letters and numbers only
      },
    },
    email: {
      required,
      email,
    },
    password: {
      checkPasswordPattern(password) {
        return (
          /[a-z]/.test(password) && // checks for a-z
          /[0-9]/.test(password) && // checks for 0-9
          /\W|_/.test(password) && // checks for special char
          password.length >= 6
        );
      },
    },
    password_confirmation: {
      required,
      sameAsPassword: sameAs("password"),
    },
  },
  methods: {
    register() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isLoading = true;

        // make Api Request and wait for Response
        this.$store
          .dispatch("register", {
            username: this.username,
            email: this.email,
            password: this.password,
            password_confirmation: this.password_confirmation,
          })
          .then(() => {
            this.isLoading = false;
            this.$store.commit("SET_REGISTER_COMPONENT", "Register2");
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

form {
  width: 400px;
  padding-top: 120px;
  align-self: center;
  margin-top: auto;
  margin-bottom: 25vh;
  @media screen and (max-width: 960px) {
    align-self: flex-end;
    width: 100%;
    max-width: 440px;
    padding: 24px;
    margin: 0;
  }
  @media screen and (max-height: 570px) {
    padding-bottom: 0;
  }
  .input__text__label {
    margin-bottom: 30px;
    @media screen and (max-width: 960px) {
      margin-bottom: 32px;
    }
    @media screen and (max-height: 570px) {
      margin-bottom: 18px;
    }
  }
  .error {
    margin-top: 8px;
    margin-left: 16px;
    @media screen and (max-height: 570px) {
      margin-top: 2px;
    }
    .error-msg {
      font-size: 14px;
      color: $red;
    }
  }
  .navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
    .btn-next {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
